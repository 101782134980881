@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&family=Roboto:ital,wght@0,400;0,700;0,900;1,100;1,500&display=swap');

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    --font-family: 'Karla', sans-serif;
    --primary-color: #001FA8;
    --card-light-color: rgba(97, 83, 254, 0.04);
    --secondary-color: #203281;
    --light-bg-color: #fafafa;
    --dark-btn-color: #ffffff;
    --light-primary-color: rgba(146, 136, 255, 0.30);
    --heading-color: #000000;
    --paragraph-color: #333333;
}


.loading-screen{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    z-index: 9999999999;
    align-items: center;
    background-color: #fff;
    justify-content: center;
}

.mt-40 {
    margin-top: 40px;
}

body {
    width: 100%;
    height: 100%;
    font-family: var(--font-family);
}

.main-container {
    width: 100%;
    position: relative;
}

.main-content {
    width: 100%;
}


/* navbar style section */
.Topbar-container {
    width: 100%;
    height: 90px;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 50px;
    position: sticky;
    top: 0px;
    z-index: 9999;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
}

.Topbar-container .logo-section {
    width: 30%;
}

.Topbar-container .logo-section .image-container {
    width: 200px;
    height: 60px;
}

.Topbar-container .logo-section .image-container img {
    width: 170px;
    margin-top: 5px;
    height: 45px;
}

.Topbar-container .links-section {
    width: 70%;
}

.Topbar-container .links-section ul {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: right;
    list-style: none;
}

.Topbar-container .links-section ul li {
    margin-left: 15px;
}

.Topbar-container .links-section ul li a {
    text-decoration: none;
    color: var(--heading-color);
    cursor: pointer;
    padding: 10px;
    transition: 0.2s;
}

.Topbar-container .links-section ul li a:hover {
    color: var(--primary-color);
}


.Topbar-container .links-section ul li a.active {
    border-radius: 0px;
    border-bottom: 2px solid var(--primary-color);
    color: var(--primary-color);
}

.Topbar-container .links-section ul li a.hire-us-button-design {
    padding: 10px 20px;
    color: var(--dark-btn-color);
    background-color: var(--secondary-color);
    border-radius: 5px;
}

.mobile-menu-icon {
    display: none;
}




/* landing page designs */

.hero-section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 30px 40px;
    background: linear-gradient(50deg, var(--secondary-color), var(--primary-color));
}

.hero-section .hero-section-detail {
    width: 50%;
}


.hero-section .hero-section-detail .hire-us-label {
    width: 100px;
    border-bottom: 2px solid var(--dark-btn-color);
    margin-bottom: 10px;
    margin-top: 40px;
}

.hero-section .hero-section-detail .hire-us-label p {
    color: var(--dark-btn-color);
    margin-bottom: 5px;
    font-size: 13px;
}

.hero-section .hero-section-detail .main-title {
    color: var(--dark-btn-color);
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.hero-section .hero-section-detail .main-description {
    color: var(--dark-btn-color);
    font-size: 16px;
    font-style: normal;
    margin-bottom: 30px;
    margin-top: 10px;
    line-height: 140%;
    /* 22.4px */
}

.hero-section .hero-section-detail .hero-section-action-btns {
    display: flex;
    align-items: center;
}


.hero-section-illustration {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: right;
}


.about-section {
    width: 100%;
    padding: 80px 40px;
    display: flex;
    background-color: var(--light-bg-color);
}

.about-section .about-details {
    width: 65%;
    display: block;
    position: relative;
}

.about-section .about-details .label {
    width: 100px;
    margin-bottom: 10px;
}

.about-section .about-details .main-title {
    font-size: 35px;
}

.about-section .about-details .main-title .highlighted-text {
    color: var(--secondary-color);
}

.about-section .about-details .main-description {
    color: var(--paragraph-color);
    font-size: 20px;
    margin-bottom: 30px;
    margin-top: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 28px */
}

.about-section .about-illustration {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: right;
}


.service-section {
    width: 100%;
    padding: 30px 40px 80px 40px;
}

.service-section .serive-top-details {
    width: 100%;
}

.service-section .serive-top-details .service-label {
    width: 100px;
    border-bottom: 2px solid var(--primary-color);
    margin-bottom: 10px;
    margin-top: 40px;
}

.service-section .serive-top-details .service-label p {
    color: 2px solid var(--primary-color);
    margin-bottom: 5px;
    font-size: 13px;
}

.service-section .serive-top-details h3 {
    font-size: 35px;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.service-section .service-card-row {
    display: flex;
    flex-wrap: wrap;
}

.service-section .service-card-row .service-half-card {
    width: 50%;
    padding-right: 10px;
    margin-top: 10px;
}

.service-section .service-card-row .service-full-card {

    margin-top: 10px;
    padding-right: 10px;

    width: 100%;
}

.service-section .service-card-row .service-full-card .service-card-wrapper {
    display: flex;
    flex-wrap: wrap;
    /* height: 250px; */
    overflow: hidden;
}

.about-illustration-container img{
    width: 100%;
}



.service-section .service-card-row .service-card-details {
    width: 100%;

}


.service-section .service-card-row .service-card-wrapper {
    padding: 15px;
    padding-bottom: 25px;
    background-color: var(--card-light-color);
    border-radius: 10px;
}

.service-section .service-card-row .service-card-wrapper .dark-label-icon {
    padding: 5px 0px;
    background-color: var(--primary-color);
    width: 30px;
    border-radius: 5px;
    text-align: center;
}

.service-section .service-card-row .service-card-wrapper .dark-label-icon i {
    font-size: 20px;
    color: white;
}


.service-section .service-card-row .service-card-wrapper .main-title {
    margin-top: 15px;
    font-size: 25px;
    margin-bottom: 10px;
}

.service-section .service-card-row .service-card-wrapper .main-description {
    margin-bottom: 25px;
    font-size: 16px;
    font-style: normal;
    line-height: 140%;
    /* 22.4px */
}

.service-section .service-card-row .service-card-wrapper img {
    margin-left: 50px;
}

.service-section .service-card-row .service-card-wrapper .dark-button-theme {
    background-color: var(--primary-color);
}



/* category section */
.category-language-section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: var(--light-bg-color);
    padding: 40px 30px;
}


.category-language-section .category-language-details {
    width: 50%;
}

.category-language-section .category-language-details .service-label {
    width: 120px;
    border-bottom: 2px solid var(--primary-color);
    margin-bottom: 10px;
    margin-top: 40px;
}

.category-language-section .category-language-details .service-label p {
    color: 2px solid var(--primary-color);
    margin-bottom: 5px;
    font-size: 13px;
}

.category-language-section .category-language-details .main-title {
    color: var(--heading-color);
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.category-language-section .category-language-details .main-title span {
    color: rgba(68, 68, 68, 0.40);
}

.category-language-section .category-language-details .main-description {
    color: #333;
    font-size: 18px;
    font-style: normal;
    line-height: 28px;
    margin-top: 15px;
    width: 85%;
    font-weight: 400;
}

.category-language-section .category-coding-icons {
    width: 50%;
    padding: 40px;
}

.category-language-section .category-coding-icons .coding-cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.category-language-section .category-coding-icons .coding-cards .coding-card {
    width: 25%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.category-language-section .category-coding-icons .coding-cards .coding-card .coding-card-wrapper {
    width: 100px;
    height: 120px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: var(--dark-btn-color);
}

.category-language-section .category-coding-icons .coding-cards .coding-card .coding-card-wrapper img {
    margin-top: 15px;
}

.category-language-section .category-coding-icons .coding-cards .coding-card .coding-card-wrapper p {
    margin-top: 5px;
}


/* proccess section */
.process-section {
    width: 100%;
    padding: 40px 30px;
}

.process-section .process-detail {
    width: 100%;
    margin-bottom: 20px;
}

.process-section .process-detail .process-label {
    width: 120px;
    border-bottom: 2px solid var(--primary-color);
    margin-bottom: 10px;
    margin-top: 40px;
}

.process-section .process-detail .process-label p {
    color: 2px solid var(--primary-color);
    margin-bottom: 5px;
    font-size: 13px;
}

.process-section .process-detail .main-title {
    color: var(--paragraph-color);
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.process-section .process-breakup-container {
    width: 100%;
    padding: 30px 20px;
    background-color: var(--primary-color);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.process-section .process-breakup-container .card-container {
    display: flex;
    flex-wrap: wrap;
}

.process-section .process-breakup-container .card-container .card-wrapper {
    display: flex;
    align-items: center;
}


.process-section .process-breakup-container .card-container .card-wrapper .card-box {
    background-color: var(--light-bg-color);
    border-radius: 10px;
    padding: 10px 10px 20px 10px;
    width: 200px;
    margin-right: 2px;
}

.process-section .process-breakup-container .card-container .card-wrapper .card-box .card-icon {
    width: 100%;
    padding: 10px;
    text-align: center;
}

.process-section .process-breakup-container .card-container .card-wrapper .card-box .card-icon img{
    width: 60%;
    height: 100px;
}

.process-section .process-breakup-container .card-container .card-wrapper .card-box .card-details {
    text-align: center;
}

.process-section .process-breakup-container .card-container .card-wrapper .card-box .card-details h4 {
    margin-top: 10px;
    margin-bottom: 15px;
    color: #222;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.process-section .process-breakup-container .card-container .card-wrapper .card-box .card-details p {

    color: #777;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.process-section .process-breakup-container .card-container .card-wrapper .card-space-dase {
    padding-left: 10px;
    padding-right: 10px;
}

/* portfolio container */
.portfolio-section {
    width: 100%;
    padding: 40px 30px;
}


.portfolio-section img{
    width: 100%;
}
.portfolio-section .porfolio-detail {
    width: 100%;
}

.portfolio-section .porfolio-detail .portfolio-label {
    width: 120px;
    border-bottom: 2px solid var(--primary-color);
    margin-bottom: 10px;
    margin-top: 40px;
}

.portfolio-section .porfolio-detail .main-title {
    color: var(--paragraph-color);
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 25px;
}


.portfolio-section .porfolio-detail .portfolio-label p {
    color: 2px solid var(--primary-color);
    margin-bottom: 5px;
    font-size: 13px;
}

.portfolio-section .portfolio-card-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.portfolio-section .portfolio-card-container .portfolio-card.full-screen {
    width: 100%;
}

.portfolio-section .portfolio-card-container .portfolio-card.one-half-screen {
    width: 60%;
}

.portfolio-section .portfolio-card-container .portfolio-card.out-half-screen {
    width: 40%;
}

.portfolio-section .portfolio-card-container .portfolio-card a {
    width: 100%;
}

.portfolio-section .portfolio-card-container .portfolio-card a .desktop-image-container {
    width: 100%;
}


.portfolio-section .portfolio-card-container .portfolio-card .desktop-image-container img {
    width: 100%;
}

.portfolio-section .portfolio-card-container .portfolio-card a .mobile-image-container {
    width: 100%;
    display: none;
}
.mobile-image-container {
    width: 100%;
    display: none;
}

/* testimonial section */
.testimonial-section {
    width: 100%;
    padding: 50px 40px;
    background-color: var(--primary-color);
}

.testimonial-section .testimonial-user{
    display: flex;
    align-items: center;
}

.testimonial-section .testimonial-user .pick-design{
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(17, 41, 150, 0.2);
}

.testimonial-section .testimonial-detail {
    width: 100%;
    margin-bottom: 20px;
}

.testimonial-section .testimonial-detail .testimonial-label {
    color: var(--dark-btn-color);
    margin-bottom: 5px;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.testimonial-section .testimonial-detail .main-title {
    color: var(--light-bg-color);
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.testimonial-section .testimonial-container {
    width: 100%;
}

.testimonial-section .testimonial-container .testimonial-card {
    padding: 15px;
    background-color: var(--dark-btn-color);
    word-wrap: break-word; /* or use overflow-wrap: break-word; */
    border-radius: 5px;
    cursor: pointer;
}

.testimonial-section .testimonial-container .testimonial-card .main-description {
    font-size: 16px;
    margin-bottom: 10px;
    text-wrap: break-word;
}

.testimonial-section .testimonial-container .testimonial-card .testimonial-stars {
    width: 100%;
    padding: 5px 0px 15px 0px;
}

.testimonial-section .testimonial-container .testimonial-card .testimonial-stars .stars {
    display: flex;
}


.testimonial-section .testimonial-container .testimonial-card .testimonial-stars .stars i {
    margin-right: 5px;
    font-size: 20px;
}

.testimonial-section .testimonial-container .testimonial-card .testimonial-stars .stars i.dark {
    color: orange;
}

.testimonial-section .testimonial-container .testimonial-card .testimonial-stars .stars i.light {
    color: #eee;
}

.testimonial-section .testimonial-container .testimonial-card .testimonial-user {
    display: flex;
    align-items: center;
}

.testimonial-section .testimonial-container .testimonial-card .testimonial-user p {
    margin-left: 10px;
    /* margin-bottom: 10px; */
    font-size: 16px;
}

.testimonial-section .testimonial-container .testimonial-card .testimonial-user img{
    width: 40px;
    height: 40px;
    border-radius: 50px;
}


/* contact section */
.contact-section {
    width: 100%;
    padding: 40px 30px;
    background-color: var(--light-bg-color);
}

.contact-section .contact-detail {
    width: 100%;
}

.contact-section .contact-detail .contact-label p {
    color: var(--secondary-color);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.contact-section .contact-detail .main-title {
    color: var(--paragraph-color);
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 25px;
}

.contact-section .contact-detail .main-title span {
    color: var(--primary-color);
}


.contact-section .contact-container {
    width: 100%;
    display: flex;
}

.contact-section .contact-container .contact-form {
    width: 50%;
}

.contact-section .contact-container .contact-form .fields {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.contact-section .contact-container .contact-form .fields .half-field {
    width: 50%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px 10px 0px;
}

.contact-section .contact-container .contact-form .fields label {
    width: 100%;
    font-size: 17px;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.contact-section .contact-container .contact-form .fields input {
    padding: 10px 15px;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(204, 204, 204, 0.30);
    background: var(--dark-btn-color);
}



.contact-section .contact-container .contact-form .fields .error-text {
    display: none;
}

.contact-section .contact-container .contact-form .fields .error .error-text {
    display: block;
    font-size: 14px;
    margin-top: 10px;
    color: red;
    font-weight: 200;
}


.contact-section .contact-container .contact-form .fields .error input,
.contact-section .contact-container .contact-form .fields .error select,
.contact-section .contact-container .contact-form .fields .error textarea {
    color: red;
    border-color: red;
}

.contact-section .contact-container .contact-form .fields select {
    padding: 10px 15px;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(204, 204, 204, 0.30);
    background: var(--dark-btn-color);
}

.contact-section .contact-container .contact-form .fields button {
    width: 150px;
    padding: 10px;
    border-radius: 50px;
    border: none;
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
    transition: 0.2s;
}

.contact-section .contact-container .contact-form .fields button:hover {
    opacity: 0.9;
}

.contact-section .contact-container .contact-form .fields textarea {
    padding: 15px 15px;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(204, 204, 204, 0.30);
    background: var(--dark-btn-color);
    height: 150px;
    resize: none;
}

.contact-section .contact-container .contact-form .fields .full-field {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px 10px 0px;
}


.contact-section .contact-container .contact-image {
    width: 50%;
    text-align: center;
    padding-top: 40px;
}




/* footer section */
.footer-container {
    width: 100%;
    padding: 40px 30px 0px 30px;
    display: flex;
    flex-wrap: wrap;
}


.footer-container .company-container {
    width: 40%;
    margin-bottom: 20px;
}

.footer-container .company-container p {
    color: #333;
    font-size: 16px;
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

.footer-container .company-container .btn {
    padding: 10px 40px;
    text-decoration: none;

    border-radius: 5px;
    background-color: var(--primary-color);
    color: var(--dark-btn-color);
}

.footer-container .link-container {
    width: 30%;
    padding: 0px 40px;
    padding-left: 80px;
}

.footer-container .link-container .label-footer {
    font-size: 20px;
    margin-bottom: 25px;
}

.footer-container .link-container ul {
    list-style: none;
}

.footer-container .link-container ul li {
    margin-bottom: 10px;
}

.footer-container .link-container ul li a {
    text-decoration: none;
    font-size: 17px;
    color: #444;
    transition: 0.2s;
    font-weight: 300;
}

.footer-container .link-container ul li a:hover {
    text-decoration: underline;
}

.footer-container .link-container ul li .social-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.footer-container .link-container ul li .social-container .social {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background-color: var(--primary-color);
    color: var(--light-bg-color);
    margin-right: 10px;
}

.footer-container .copyright-contain {
    width: 100%;
    padding: 15px;
    text-align: center;
    margin-top: 20px;
    border-top: 1px solid #eee;
    font-size: 14px;
    color: #444;
}


/* team section */
.team-section {
    width: 100%;
}

.team-section .team-hero-section {
    padding: 40px 40px;
    display: flex;
    flex-wrap: wrap;
    background-color: var(--light-bg-color);
    width: 100%;
}

.team-section .team-hero-section .hero-detail {
    width: 50%;
    padding-top: 60px;
}


.team-section .label {
    width: 100px;
    font-size: 12px;
    margin-bottom: 10px;
}

.team-section .team-hero-section .hero-detail p {
    color: #111;
    font-family: Karla;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

.team-section .team-hero-section .hero-image {
    width: 50%;
    display: flex;
    justify-content: right;
    align-items: center;
    padding-top: 30px;
}
.team-section .team-hero-section .hero-image img{
 max-width: 100%;
}
.our-mission-section {
    padding: 60px 30px;
}

.our-mission-section .label {
    width: 130px;
}

.our-mission-section .main-title {
    color: #111;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.our-mission-section .main-description {
    color: #060606;
    font-family: Karla;
    font-size: 20px;
    width: 95%;
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 28px */
}


.our-team-container {
    width: 100%;
    padding: 40px 30px;
    background-color: var(--primary-color);
}

.our-team-container .our-team-detail {
    width: 100%;
}

.our-team-container .our-team-detail .label {
    background-color: rgba(255, 255, 255, 0.3);
    color: var(--light-bg-color);
}

.our-team-container .our-team-detail .main-title {
    color: var(--light-bg-color);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.our-team-container .our-team-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
}

.our-team-container .our-team-cards .our-team-card {
    width: 20%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}


.our-team-container .our-team-cards .our-team-card .our-team-card-wrapper {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    overflow: hidden;
    cursor: pointer;
    width: 80%;
}

.our-team-container .our-team-cards .our-team-card .our-team-card-wrapper .image-container {
    width: 100%;
}

.our-team-container .our-team-cards .our-team-card .our-team-card-wrapper .image-container img {
    width: 100%;
    border-radius: 10px;
}

.our-team-container .our-team-cards .our-team-card h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 10px 0px 3px 0px;
}

.our-team-container .our-team-cards .our-team-card p {
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
}



.portfolio-main-container .portfolio-section {
    margin-top: 60px;
}


/* service page */
.service-page-container {
    width: 100%;
}

.service-page-container .service-hero-section {
    width: 100%;
    background-color: var(--light-bg-color);
    padding: 60px 40px;
}

.service-page-container .service-hero-section .label {
    width: 250px;
    font-size: 14px;
}

.service-page-container .service-hero-section h3 {
    color: #111;
    font-size: 40px;
    margin-top: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.service-page-container .service-hero-section p {
    color: #333;
    font-family: Karla;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    width: 50%;
    margin-top: 20px;
    line-height: normal;
}



.the-right-fit {
    width: 100%;
    padding: 40px 30px;
    margin-top: 30px;
}

.the-right-fit .the-right-detail {
    width: 100%;
}

.the-right-fit .the-right-detail .label {
    width: 150px;
    font-size: 14px;
}

.the-right-fit .the-right-detail h3 {
    color: #111;
    margin-top: 20px;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.the-right-fit .the-right-fit-cards {
    display: flex;
    margin-top: 30px;
    width: 100%;
    flex-wrap: wrap;
}


.the-right-fit .the-right-fit-cards .the-right-fit-card {
    width: 33%;
    padding: 10px;
}

.the-right-fit .the-right-fit-cards .the-right-fit-card .card-wrapper {
    border-radius: 10px;
    border: 1px solid #CCC;
    background: var(--light-bg-color);
    padding: 25px 15px;
}

.the-right-fit .the-right-fit-cards .the-right-fit-card .card-wrapper h3 {
    font-size: 30px;
    margin-bottom: 15px;
}

.the-right-fit .the-right-fit-cards .the-right-fit-card .card-wrapper p {
    font-size: 18px;
    color: var(--paragraph-color);
}


.offer-section {
    width: 100%;
    padding: 40px 30px;
    background-color: var(--primary-color);
}

.offer-section .offer-detail {
    width: 100%;
}

.offer-section .offer-detail .label {
    background-color: rgba(255, 255, 255, 0.3);
    width: 140px;
    color: var(--light-bg-color);
}

.offer-section .offer-detail h3 {
    color: var(--light-bg-color);
    font-size: 40px;
    margin-top: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.offer-section .offer-cards-container {
    display: flex;
    width: 100%;
    margin-top: 20px;
    flex-wrap: wrap;
}

.offer-section .offer-cards-container .offer-card {
    width: 50%;
    margin-bottom: 20px;
    display: flex;
    padding-right: 20px;
    justify-content: center;
}

.offer-section .offer-cards-container .offer-card .card-wrapper {
    padding: 15px;
    overflow: hidden;
    height: 130px;
    position: relative;
    width: 100%;
    background-color: white;
    border-radius: 5px;
}

.offer-section .offer-cards-container .offer-card .card-wrapper h3 {
    color: var(--heading-color);
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.offer-section .offer-cards-container .offer-card .card-wrapper p.main-description {
    color: #333;
    width: 90%;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.offer-section .offer-cards-container .offer-card .card-wrapper .number-label {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top: -30px;
    right: -20px;
    background-color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
}


.offer-section .offer-cards-container .offer-card .card-wrapper .number-label p {
    font-size: 40px;
    margin-top: 20px;
    margin-left: -10px;
    color: white;
}


.faq-container {
    width: 100%;
    padding: 60px 30px;
    background-color: var(--light-bg-color);
}

.faq-container .faq-container-detail {
    width: 100%;
    margin-bottom: 20px;
}

.faq-container .faq-container-detail .label {
    width: 100px;

}

.faq-container .faq-container-detail h3 {
    color: var(--heading-color);
    font-size: 40px;
    margin-top: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.faq-container .faq-container-detail p {
    color: #333;
    font-family: Karla;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.faq-cards-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}

.faq-cards-row .faq-card {
    width: 50%;
    margin-bottom: 25px;
}

.faq-cards-row .faq-card .faq-wrapper {
    width: 95%;
    border-radius: 10px;
    border: 1px solid #CCC;
    background: #FFF;
    padding: 20px;
}

.faq-cards-row .faq-card .question {
    display: flex;
    justify-content: space-between;
}

.faq-cards-row .faq-card .question h3 {
    color: #111;

    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.faq-cards-row .faq-card .question button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.faq-cards-row .faq-card .question button i {
    font-size: 20px;
}

.faq-cards-row .faq-card .answer {

    height: 0px;
    overflow: hidden;
}


.faq-cards-row .faq-card.open .answer {
    height: 100%;
    overflow: hidden;
    margin-top: 15px;
}

.faq-cards-row .faq-card.open .answer p {
    color: #333;
    font-family: Karla;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

/* portfolio page */
.portfolio-container {
    width: 100%;
}

.portfolio-container .porfolio-banner {
    width: 100%;
    padding: 30px 30px;
}

.portfolio-container .porfolio-banner img {
    width: 100%;
    height: 450px;
    border-radius: 6px;
}

.portfolio-container .portfolio-section {
    width: 100%;
    background-color: var(--light-bg-color);
}

.portfolio-container .portfolio-section .portfolio-details {
    width: 100%;
}

.portfolio-container .portfolio-section .portfolio-details .label {
    width: 150px;
    font-size: 15px;
}

.portfolio-container .portfolio-section .portfolio-details h3 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    margin: 20px 0px;
    line-height: normal;
}

.portfolio-container .portfolio-section .portfolio-cards {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.portfolio-container .portfolio-section .portfolio-card {
    width: 33%;
    padding: 15px;
}

.portfolio-container .portfolio-section .portfolio-card .card-wrapper {
    padding: 20px 20px;
    border-radius: 10px;
    border: 1px solid #CCC;
    background: #FFF;
}

.portfolio-container .portfolio-section .portfolio-card .card-wrapper .icon{
    width: 40px;
    height: 40px;
    overflow: hidden;
}



.portfolio-container .portfolio-section .portfolio-card .card-wrapper .icon img{
    width: 100%;
    height: 100%;
}

.portfolio-feature-section {
    width: 100%;
    padding: 0px 30px;
}

.portfolio-feature-section .portfolio-card {
    width: 100%;
    padding: 100px 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.portfolio-feature-section .portfolio-card.left-to-right{
    flex-direction: row-reverse;
    background-color: var(--light-bg-color);
}

.portfolio-feature-section .portfolio-card .portfolio-detail {
    width: 50%;
}

.portfolio-feature-section .portfolio-card .portfolio-detail .label {
    font-size: 14px;
    width: 200px;
}


.portfolio-feature-section .portfolio-card .portfolio-detail .main-title {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 30px;
    line-height: normal;
}

.portfolio-feature-section .portfolio-card .portfolio-detail .main-description {
    color: var(--paragraph-color);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.portfolio-feature-section .portfolio-card .portfolio-image {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: right;
}

.portfolio-feature-section .portfolio-card .portfolio-image img{
    width: 100%;
}
.portfolio-feature-section .portfolio-card.left-to-right .portfolio-image{
    justify-content: left;
}


.portfolio-container .portfolio-section .portfolio-card .card-wrapper .card-detail {
    margin-top: 10px;
    margin-bottom: 10px;
}


.portfolio-container .portfolio-section .portfolio-card .card-wrapper .card-detail h3 {
    font-size: 25px;
    margin-bottom: 10px;
}

.portfolio-container .portfolio-section .portfolio-card .card-wrapper .card-detail p {
    color: #333;
    font-family: Karla;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}



/* send quote section */
.send-quote .contact-container .contact-form{
    width: 60%;
}

.send-quote .contact-container .contact-information{
    width: 40%;
    padding: 20px;
}

.send-quote .contact-container .contact-information .blue-container-box{
    padding: 20px;
    background-color: var(--primary-color);
    border-radius: 10px;
    color: white;
}

.send-quote .contact-container .contact-information .blue-container-box .main-title{
    font-size: 30px;
}

.send-quote .contact-container .contact-information .blue-container-box .main-description{
    font-size: 20px;
    margin-top: 10px;
    font-weight: 300;
    width: 90%;
    margin-bottom: 20px;
}

.send-quote .contact-container .contact-information .blue-container-box .social-container{
    display: flex;
    flex-wrap: wrap;
}

.send-quote .contact-container .contact-information .blue-container-box .social-container .social{
    width: 40px;
    height: 40px;display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--light-bg-color);
    border-radius: 50%;
    text-decoration: none;
    font-size: 20px;
    color: var(--primary-color);
    margin-right: 10px;
    cursor: pointer;
}

.send-quote .contact-container .contact-information .blue-container-box ul{
    list-style: none;
}

.send-quote .contact-container .contact-information .blue-container-box ul li{
    display: flex;
    align-items: center;
    padding: 10px 0px;
    margin-top: 10px;
}

.send-quote .contact-container .contact-information .blue-container-box ul li i{
    font-size: 20px;
}

.send-quote .contact-container .contact-information .blue-container-box ul li  a{
    font-size: 20px;
    text-decoration: none;
    color: var(--light-bg-color);
    margin-left: 10px;
    margin-bottom: 3px;
}
/* progress container */
.progress-container {
    width: 100%;
    padding: 40px 30px;
}

.progress-container .main-title {
    color: var(--heading-color);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.progress-container .progress-card-rows{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.progress-container .progress-card-rows .progress-card{
    width: 25%;
    padding: 25px;
}

.progress-container .progress-card-rows .progress-card-wrapper{
    padding:40px 25px 25px 25px;
    background-color: var(--secondary-color);
    border-radius: 10px;
    text-align: center;
}
.CircularProgressbar .CircularProgressbar-path{
    stroke: #6153FE;
}


.progress-container .progress-card-rows .progress-card-wrapper h3{
    color: var(--dark-btn-color);
    margin-top: 20px;
    font-size: 22px;
}

.CircularProgressbar .CircularProgressbar-text{
    fill: white;
}

.CircularProgressbar{
    width: 60%;
    margin: auto    ;
}

.CircularProgressbar .CircularProgressbar-trail{
    stroke: white;
}

.swiper-slide {
    height: 270px;
}

.swiper-pagination {
    left: 0px !important;
    text-align: left;
}

.swiper-pagination-bullet {
    width: 25px;
    height: 5px;
    background-color: var(--light-bg-color);
    border-radius: 5px;
    opacity: 1;
    transition: 0.5s;
}

.swiper-pagination-bullet-active {
    background-color: var(--secondary-color);
    width: 35px;
}


/* button design */
.dark-button-theme {
    padding: 10px 20px;
    color: var(--dark-btn-color);
    text-decoration: none;
    background-color: var(--secondary-color);
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.12));
    border-radius: 5px;
    cursor: pointer;
}

.social-button {
    padding: 5px 8px;
    margin-left: 15px;
    color: var(--primary-color);
    background-color: var(--dark-btn-color);
    border-radius: 5px;
    cursor: pointer;
}

.not-found-section{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.not-found-section .detail-container{
    width: 50%;
    padding: 60px 50px;
}

.not-found-section .detail-container h3{
    font-size: 50px;
}

.not-found-section .detail-container p{
    font-size: 20px;
    margin-top: 20px;
    color: var(--paragraph-color);
}

.not-found-section .detail-container a{
    padding: 10px 20px;
    margin-top: 20px;
    display: block;
    width: 150px;
    text-align: center;
    border-radius: 5px;
    color: var(--light-bg-color);
    text-decoration: none;
    background-color: var(--heading-color);
}

.portfolio-button{
    width: 100%;
    text-align: center;
    padding: 40px 0px;
}

.portfolio-button a{
    padding: 10px 40px;
    background-color: var(--primary-color);
    border-radius: 30px;
    color: var(--dark-btn-color);
    text-decoration: none;
}

.not-found-section  .image-container{
    width: 50%;
    margin: auto;
    padding: 10px 50px;
}

.not-found-section  .image-container img{
    width: 100%;
}
/* label design */

.label {
    padding: 10px 0px;
    color: var(--secondary-color);
    border-radius: 20px;
    text-align: center;
    display: block;
    background-color: var(--light-primary-color);
}

.label p {
    font-size: 12px;
}



.trems-condition{
    padding: 40px;
}

.trems-condition h1{
    margin-bottom: 30px;
    color: var(--primary-color);
}

.sucess-message{
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background-color: lightgreen;
    color: green;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
}